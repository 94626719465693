// Units in ms
export const LETTER_FADE_OUT_DURATION = 100;
export const LETTER_FADE_OUT_DELAY_STEP = 50;
export const LETTER_FADE_IN_DURATION = 300;
export const LETTER_FADE_IN_DELAY_STEP = 100;
export const FADE_OUT_DURATION =
  6 * LETTER_FADE_OUT_DELAY_STEP + LETTER_FADE_OUT_DURATION;
export const FADE_IN_DURATION =
  6 * LETTER_FADE_IN_DELAY_STEP + LETTER_FADE_IN_DURATION;

export const BADGE_MODAL_FADE_DURATION = 200;
