import { useEffect, useState } from "react";
import { calculateScore, getDateString } from "../models/Utils";
import { getUsersByDate } from "../api/User.api";
import { User } from "../models/User.model";
import { getPuzzle } from "../api/Puzzle.api";
import { Puzzle } from "../models/Puzzle.model";
import styled from "styled-components";
import {
  RevealedPangram,
  RevealedAnswer,
  UserCard,
  LETTER_LENGTH,
  LeaderboardDivider,
  Button,
  Tab,
  Score,
  UserCardTitle,
} from "./Common";
import Title from "./Title";
import { BadgeSection, generateBadgesForYesterday } from "./Badges";
import EventLog from "./EventLog";
import { EventLogMessage } from "../models/EventLogMessage.model";
import { getEvents } from "../api/Event.api";

interface YesterdayScoresProps {
  userId: string;
  navigate: (t: Tab) => void;
}

const ScoresheetContainer = styled.div<{ width: number }>`
  display: flex;
  overflow-x: auto;
  margin-bottom: 50px;
  justify-content: ${({ width }) =>
    window.innerWidth > width ? "center" : "left"};
`;

const BlankAnswer = styled.div`
  height: 24px;
`;

const BackButton = styled(Button)`
  margin-bottom: 74px;
`;

const MissedAnswer = styled(RevealedAnswer)`
  background-color: ${(props) => props.theme.SECONDARY};
`;

const MissedPangram = styled(RevealedPangram)`
  background-color: ${(props) => props.theme.SECONDARY};
`;

const DispayDate = styled.div`
  margin-bottom: 30px;
`;

const YesterdayLetters = styled.div`
  margin-bottom: 30px;
  font-size: 32px;
  display: flex;
  justify-content: center;
`;

const Letter = styled.div`
  width: 38px;
`;

const YesterdayCenterLetter = styled(Letter)`
  font-weight: bold;
  color: ${(props) => props.theme.PRIMARY};
`;

const YesterdayScores = ({ userId, navigate }: YesterdayScoresProps) => {
  const [yesterdayPuzzle, setYesterdayPuzzle] = useState<Puzzle | null>(null);
  const [users, setUsers] = useState<User[] | null>(null);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [userCardWidth, setUserCardWidth] = useState<number | null>(null);
  const [events, setEvents] = useState<EventLogMessage[] | null>(null);

  useEffect(() => {
    const yesterday = new Date();
    yesterday.setTime(yesterday.getTime() - 24 * 60 * 60 * 1000);
    const dateString = getDateString(yesterday);
    getPuzzle(dateString).then((res) => {
      res.answers.sort((a, b) =>
        res.pangrams.includes(a)
          ? -1
          : res.pangrams.includes(b)
          ? 1
          : a.localeCompare(b)
      );
      setYesterdayPuzzle(res);
      const maxWordLength = Math.max(...res.answers.map((a) => a.length));
      setUserCardWidth(maxWordLength * LETTER_LENGTH + 20);
    });
    getUsersByDate(dateString).then((users) => {
      const usersWithAnswers = users.filter((u) => u.answers.length > 0);
      setUsers(usersWithAnswers);
      const current = usersWithAnswers.find((u) => u.userId === userId);
      if (current) setCurrentUser(current);
    });
    getEvents(dateString).then((res) => {
      setEvents(res);
    });
  }, [userId]);

  return (
    <>
      <Title />
      {yesterdayPuzzle && users && userCardWidth ? (
        <>
          <DispayDate>{yesterdayPuzzle.displayDate}</DispayDate>
          <YesterdayLetters>
            <YesterdayCenterLetter>
              {yesterdayPuzzle.centerLetter.toUpperCase()}
            </YesterdayCenterLetter>
            {yesterdayPuzzle.outerLetters.map((l) => (
              <Letter key={l}>{l.toUpperCase()}</Letter>
            ))}
          </YesterdayLetters>
          <ScoresheetContainer width={userCardWidth * (users.length + 1)}>
            <UserCard width={userCardWidth}>
              <UserCardTitle $self={false}>Answers</UserCardTitle>
              <LeaderboardDivider />
              <br />
              <LeaderboardDivider />
              <BadgeSection />
              <LeaderboardDivider />
              {yesterdayPuzzle.answers.map((a) =>
                currentUser && !currentUser.answers.includes(a) ? (
                  yesterdayPuzzle.pangrams.includes(a) ? (
                    <MissedPangram key={a}>{a.toUpperCase()}</MissedPangram>
                  ) : (
                    <MissedAnswer key={a}>{a.toUpperCase()}</MissedAnswer>
                  )
                ) : yesterdayPuzzle.pangrams.includes(a) ? (
                  <RevealedPangram key={a}>{a.toUpperCase()}</RevealedPangram>
                ) : (
                  <RevealedAnswer key={a}>{a.toUpperCase()}</RevealedAnswer>
                )
              )}
            </UserCard>
            {users
              .sort(
                (u1, u2) =>
                  calculateScore(u2.answers, yesterdayPuzzle.pangrams) -
                  calculateScore(u1.answers, yesterdayPuzzle.pangrams)
              )
              .map((u) => (
                <UserCard key={u.userId} width={userCardWidth}>
                  <UserCardTitle $self={u.userId === userId}>
                    {u.userId}
                  </UserCardTitle>
                  <LeaderboardDivider />
                  <Score>
                    {calculateScore(u.answers, yesterdayPuzzle.pangrams)}
                  </Score>
                  <LeaderboardDivider />
                  <BadgeSection>
                    {generateBadgesForYesterday(
                      u,
                      users,
                      yesterdayPuzzle.pangrams
                    )}
                  </BadgeSection>
                  <LeaderboardDivider />
                  {yesterdayPuzzle.answers.map((a) =>
                    u.answers.includes(a) ? (
                      yesterdayPuzzle.pangrams.includes(a) ? (
                        <RevealedPangram key={a}>
                          {a.toUpperCase()}
                        </RevealedPangram>
                      ) : (
                        <RevealedAnswer key={a}>
                          {a.toUpperCase()}
                        </RevealedAnswer>
                      )
                    ) : (
                      <BlankAnswer key={a} />
                    )
                  )}
                </UserCard>
              ))}
          </ScoresheetContainer>
          {events && events.length > 0 && <EventLog events={events} />}
          <BackButton onClick={() => navigate("homepage")}>Back</BackButton>
        </>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default YesterdayScores;
