import axios from "axios";
import { EventLogMessage } from "../models/EventLogMessage.model";

const SERVER = process.env.REACT_APP_SERVER_BASE;

export const getEvents = async (date: string): Promise<EventLogMessage[]> => {
  const response = await axios.get<EventLogMessage[]>(
    `${SERVER}/events/${date}`
  );

  if (response.status !== 200) throw new Error("Events not found");
  else return response.data;
};
