import styled from "styled-components";

const Text = styled.div`
  text-align: center;
  line-height: 18px;
  margin: 90px 0px 30px 0px;
`;

const Title = () => {
  return <Text>Words with Associates</Text>;
};

export default Title;
