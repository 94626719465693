import axios from "axios";
import { Puzzle } from "../models/Puzzle.model";

const SERVER = process.env.REACT_APP_SERVER_BASE;

export const getPuzzle = async (printDate: string): Promise<Puzzle> => {
  const response = await axios.get<Puzzle>(`${SERVER}/puzzle/${printDate}`);

  if (response.status !== 200) throw new Error("Puzzle not found");
  else return response.data;
};
