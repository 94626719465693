import { useCallback, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import Letters from "./Letters";
import AnswerList from "./AnswerList";
import { getPuzzle } from "../api/Puzzle.api";
import { getUsersByDate, updateUser } from "../api/User.api";
import { Puzzle } from "../models/Puzzle.model";
import _ from "lodash";
import Leaderboard from "./Leaderboard";
import { User, UserGuess } from "../models/User.model";
import FeedbackCard from "./FeedbackCard";
import { publish } from "../events";
import { Button, Tab } from "./Common";
import { getDateString } from "../models/Utils";
import Title from "./Title";
import { FADE_IN_DURATION, FADE_OUT_DURATION } from "../models/Constants";
import Changelog from "./Changelog";
import EventLog from "./EventLog";
import { EventLogMessage } from "../models/EventLogMessage.model";
import { getEvents } from "../api/Event.api";

interface HomePageProps {
  userId: string;
  navigate: (t: Tab) => void;
}

const cursorBlink = keyframes`
  0% {
    opacity: 0;
  }
`;

const GuessDisplay = styled.div`
  margin: 30px auto;
  font-size: 32px;
  height: 40px;
  line-height: 40px;
  display: flex;
  justify-content: center;

  &:after {
    content: "";
    width: 4px;
    height: 40px;
    background-color: ${(props) => props.theme.PRIMARY};
    display: inline-block;

    animation: ${cursorBlink} 1s steps(2) infinite;
  }
`;

const CenterSpan = styled.div`
  color: ${(props) => props.theme.PRIMARY};
`;

const ValidSpan = styled.div`
  color: ${(props) => props.theme.TEXT};
`;

const InvalidSpan = styled.div`
  color: ${(props) => props.theme.DISABLED};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

const YesterdayButton = styled(Button)`
  margin: 20px 10px;
`;

const HomePage = ({ userId, navigate }: HomePageProps) => {
  const [puzzleData, setPuzzleData] = useState<Puzzle | null>(null);
  const [allUsers, setAllUsers] = useState<User[] | null>(null);
  const [activeUsers, setActiveUsers] = useState<User[] | null>(null);
  const [revealedWords, setRevealedWords] = useState<string[]>([]);
  const [shuffledLetters, setShuffledLetters] = useState<string[]>([]);
  const [guess, setGuess] = useState("");
  const [isShuffling, setIsShuffling] = useState<boolean>(false);
  const [events, setEvents] = useState<EventLogMessage[] | null>(null);

  useEffect(() => {
    const today = getDateString(new Date());
    // const today = "2024-06-09";
    getPuzzle(today).then((res) => {
      setPuzzleData(res);
      setShuffledLetters(res.outerLetters);
    });
    getUsersByDate(today)
      .then((users) => {
        const usersWithAnswers = users.filter((u) => u.answers.length > 0);
        setActiveUsers(usersWithAnswers);
        setAllUsers(users);
        const signedInUser = usersWithAnswers.find((u) => u.userId === userId);
        if (signedInUser) setRevealedWords(signedInUser.answers);
      })
      .catch((e) => {});
    getEvents(today).then((res) => {
      setEvents(res);
    });
  }, [userId]);

  const onShuffle = useCallback(() => {
    if (!isShuffling) {
      const today = getDateString(new Date());
      getUsersByDate(today).then((users) => {
        const usersWithAnswers = users.filter((u) => u.answers.length > 0);
        setActiveUsers(usersWithAnswers);
        setAllUsers(users);
        const signedInUser = usersWithAnswers.find((u) => u.userId === userId);
        if (signedInUser) setRevealedWords(signedInUser.answers);
      });
      getEvents(today).then((res) => {
        setEvents(res);
      });
      setIsShuffling(true);
      publish("Shuffle", null);
      window.setTimeout(() => {
        setShuffledLetters(_.shuffle(shuffledLetters));
      }, FADE_OUT_DURATION);
      window.setTimeout(() => {
        setIsShuffling(false);
      }, FADE_OUT_DURATION + FADE_IN_DURATION);
    }
  }, [isShuffling, shuffledLetters, userId]);

  const onGuess = useCallback(() => {
    const lowerCaseGuess = guess.toLowerCase();
    publish("Guess", lowerCaseGuess);

    if (
      allUsers &&
      puzzleData &&
      lowerCaseGuess.length >= 4 &&
      lowerCaseGuess.split("").every((c) => puzzleData.validLetters.includes(c))
    ) {
      const signedInUser = allUsers.find((u) => u.userId === userId);

      if (signedInUser && !signedInUser.guessCount) signedInUser.guessCount = 0;

      const payload: UserGuess = {
        user: {
          userId: userId,
          answers: signedInUser ? signedInUser.answers : [],
          date: puzzleData.printDate,
          pangramStreak: signedInUser ? signedInUser.pangramStreak : 0,
          guessCount: signedInUser ? signedInUser.guessCount + 1 : 1,
        },
        guess: lowerCaseGuess,
      };

      if (
        puzzleData.answers.includes(lowerCaseGuess) &&
        !revealedWords.includes(lowerCaseGuess)
      ) {
        const sortedWords = [lowerCaseGuess, ...revealedWords].sort((a, b) =>
          a.localeCompare(b)
        );

        payload.user.answers = sortedWords;
      }

      if (
        puzzleData.pangrams.includes(lowerCaseGuess) &&
        !revealedWords.some((word) => puzzleData.pangrams.includes(word))
      ) {
        payload.user.pangramStreak += 1;
      }

      updateUser(payload).then(() => {
        const today = getDateString(new Date());
        getUsersByDate(today).then((users) => {
          const usersWithAnswers = users.filter((u) => u.answers.length > 0);
          setActiveUsers(usersWithAnswers);
          setAllUsers(users);
          const signedInUser = usersWithAnswers.find(
            (u) => u.userId === userId
          );
          if (signedInUser) setRevealedWords(signedInUser.answers);
        });
        getEvents(today).then((res) => {
          setEvents(res);
        });
      });
    }

    setGuess("");
  }, [guess, puzzleData, revealedWords, userId, allUsers]);

  useEffect(() => {
    const keydownListener = (e: KeyboardEvent) => {
      const uppercase = e.key.toUpperCase();
      if (!e.metaKey && uppercase.length === 1 && uppercase.match(/[A-Z]/i)) {
        const newGuess = guess + e.key.toUpperCase();
        setGuess(newGuess);
      } else if (e.key === " ") {
        e.preventDefault();
        onShuffle();
      } else if (e.key === "Enter") {
        e.preventDefault();
        onGuess();
      } else if (e.key === "Backspace" && guess.length > 0) {
        setGuess(guess.substring(0, guess.length - 1));
      }
    };

    document.addEventListener("keydown", keydownListener);

    return () => {
      document.removeEventListener("keydown", keydownListener);
    };
  }, [guess, onShuffle, onGuess]);

  const onDelete = () => {
    if (guess.length > 0) {
      setGuess(guess.substring(0, guess.length - 1));
    }
  };

  const onClickLetter = (c: string) => {
    setGuess(guess + c.toUpperCase());
  };

  return (
    <>
      <Title />
      {puzzleData && allUsers ? (
        <>
          <FeedbackCard puzzleData={puzzleData} revealedWords={revealedWords} />
          <GuessDisplay>
            {guess
              .split("")
              .map((l, index) =>
                l.toLowerCase() === puzzleData.centerLetter ? (
                  <CenterSpan key={index}>{l}</CenterSpan>
                ) : puzzleData.outerLetters.includes(l.toLowerCase()) ? (
                  <ValidSpan key={index}>{l}</ValidSpan>
                ) : (
                  <InvalidSpan key={index}>{l}</InvalidSpan>
                )
              )}
          </GuessDisplay>
          <Letters
            centerLetter={puzzleData.centerLetter}
            outerLetters={shuffledLetters}
            onClickLetter={onClickLetter}
          />
          <ButtonContainer>
            <Button onClick={onDelete}>Delete</Button>
            <Button onClick={onShuffle}>Shuffle</Button>
            <Button onClick={() => onGuess()}>Enter</Button>
          </ButtonContainer>
          <AnswerList
            revealedAnswers={revealedWords}
            pangrams={puzzleData.pangrams}
          />
          {activeUsers && (
            <Leaderboard
              userId={userId}
              activeUsers={activeUsers}
              revealedWords={revealedWords}
              pangrams={puzzleData.pangrams}
              answers={puzzleData.answers}
            />
          )}
          {events && events.length > 0 && <EventLog events={events} />}
          <YesterdayButton onClick={() => navigate("yesterday")}>
            Yesterday
          </YesterdayButton>
          <Button
            onClick={() =>
              window.open("https://swa.dillonhammer.com", "_blank")
            }
          >
            Play SWA
          </Button>
          <Changelog />
        </>
      ) : (
        <>
          <div>Loading...</div>
          <br />
          <div>
            {
              "[5/12/2024] If the site is down feel free to just email dillonhammer@gmail.com with subject 'WWA Down' and I'll manually restart it when I'm able. Auto-restarting is high on my todo list so hopefully this won't be an issue soon."
            }
          </div>
        </>
      )}
    </>
  );
};

export default HomePage;
