import { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { subscribe, unsubscribe } from "../events";
import { calculateScore } from "../models/Utils";
import { Score } from "./Common";
import { Puzzle } from "../models/Puzzle.model";
import ScoreSlider from "./ScoreSlider";

interface FeedbackCardProps {
  puzzleData: Puzzle;
  revealedWords: string[];
}

const EmptyContainer = styled.div`
  height: 44px;
  line-height: 44px;
`;

const fadeInOut = keyframes`
  0% { opacity: 0; }
  10% { opacity: 1; }
  90% { opacity: 1; }
  100% { opacity: 0 }
`;

const Feedback = styled(EmptyContainer)<{ type: string }>`
  animation: ${fadeInOut} 5s forwards;
  background-color: ${(props) => {
    switch (props.type) {
      case "incorrect":
        return props.theme.DISABLED;
      case "correct":
      case "pangram":
        return props.theme.SECONDARY;
    }
  }};
  color: ${(props) => (props.type === "pangram" ? props.theme.PRIMARY : "")};
  font-weight: ${(props) => (props.type === "pangram" ? "bold" : "")};
`;

const FeedbackCard = ({ puzzleData, revealedWords }: FeedbackCardProps) => {
  const [message, setMessage] = useState("");
  const timeoutRef = useRef<number | null>(null);
  const revealedWordsRef = useRef<string[]>(revealedWords);

  useEffect(() => {
    revealedWordsRef.current = revealedWords;
  }, [revealedWords]);

  useEffect(() => {
    const guessListener = ({ detail: guess }: any) => {
      if (guess.length < 4) {
        setMessage("Too short");
      } else if (
        guess
          .split("")
          .some((c: string) => !puzzleData.validLetters.includes(c))
      ) {
        setMessage("Invalid letters");
      } else if (!guess.includes(puzzleData.centerLetter)) {
        setMessage("Missing center letter");
      } else if (revealedWordsRef.current.includes(guess)) {
        setMessage("Already found");
      } else if (puzzleData.answers.includes(guess)) {
        setMessage(guess);
      } else {
        setMessage("Not a word");
      }

      // Necessary to restart the animation if feedback already showing
      const feedbackDiv = document.getElementById("feedbackDiv");
      if (feedbackDiv) {
        feedbackDiv.style.visibility = "hidden";
        feedbackDiv.style.animation = "none";
        setTimeout(() => {
          feedbackDiv.style.visibility = "visible";
          feedbackDiv.style.animation = "";
        }, 10);
      }

      const id = window.setTimeout(() => {
        setMessage("");
      }, 5000);

      if (timeoutRef.current !== null) window.clearTimeout(timeoutRef.current);

      timeoutRef.current = id;
    };

    subscribe("Guess", guessListener);

    return () => {
      unsubscribe("Guess", guessListener);
    };
  }, [puzzleData]);

  return message ? (
    <Feedback
      id="feedbackDiv"
      type={
        puzzleData.answers.includes(message)
          ? puzzleData.pangrams.includes(message)
            ? "pangram"
            : "correct"
          : "incorrect"
      }
    >
      {puzzleData.answers.includes(message) ? (
        <>
          {message.toUpperCase()}
          <Score>+{calculateScore([message], puzzleData.pangrams)}</Score>
        </>
      ) : (
        message
      )}
    </Feedback>
  ) : (
    <ScoreSlider puzzleData={puzzleData} revealedWords={revealedWords} />
  );
};

export default FeedbackCard;
