import { useEffect, useState } from "react";
import styled, { keyframes, css } from "styled-components";
import { subscribe, unsubscribe } from "../events";
import {
  FADE_OUT_DURATION,
  LETTER_FADE_IN_DELAY_STEP,
  LETTER_FADE_IN_DURATION,
  LETTER_FADE_OUT_DELAY_STEP,
  LETTER_FADE_OUT_DURATION,
} from "../models/Constants";

interface LettersProps {
  centerLetter: string;
  outerLetters: string[];
  onClickLetter: (c: string) => void;
}

const SectionContainer = styled.div`
  font-weight: bold;
  font-size: 64px;
  justify-content: center;
  margin-bottom: 20px;
`;

const LetterRow = styled.div`
  height: 100px;
`;

const LetterContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
`;

const fadeOut = keyframes`
  0% {
    visibility: visible;
  }
  100% { 
    visibility: hidden;
    width: 0px;
    height: 0px;
    border-width: 0px;
    border-radius: 0px;
    font-size: 0px;
    line-height: 0px;
    margin-top: 0px;
    margin-left: 0px;
}
`;

const fadeIn = keyframes`
  0% {
    visibility: visible;
    width: 0px;
    height: 0px;
    border-width: 5px;
    border-radius: 0px;
    font-size: 0px;
    line-height: 0px;
    margin-top: 0px;
    margin-left: 0px;
  }
  100% { 
    visibility: visible;
  }
`;

const OuterLetter = styled.div<{
  $fadingOut: boolean;
  $fadingIn: boolean;
  $fadeOrder: number;
}>`
  background-color: ${(props) => props.theme.SECONDARY};
  color: ${(props) => props.theme.PRIMARY};
  border: 5px solid ${(props) => props.theme.PRIMARY};
  border-radius: 5px;
  text-align: center;
  width: 80px;
  height: 80px;
  line-height: 80px;
  cursor: pointer;
  touch-action: none;

  &:hover {
    color: ${(props) => props.theme.HIGHLIGHT};
    border: 5px solid ${(props) => props.theme.HIGHLIGHT};
  }

  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -44px;
  margin-left: -44px;
  visibility: ${({ $fadingOut: fadingOut }) =>
    fadingOut ? "visible" : "hidden"};

  animation: ${({
    $fadingOut: fadingOut,
    $fadingIn: fadingIn,
    $fadeOrder: fadeOrder,
  }) => {
    if (fadingOut) {
      return css`
        ${LETTER_FADE_OUT_DURATION}ms ${fadeOut} ${fadeOrder *
        LETTER_FADE_OUT_DELAY_STEP}ms forwards;
      `;
    } else if (fadingIn) {
      return css`
        ${LETTER_FADE_IN_DURATION}ms ${fadeIn} ${fadeOrder *
        LETTER_FADE_IN_DELAY_STEP}ms forwards;
      `;
    } else {
      return "none;";
    }
  }};
`;

const CenterLetter = styled(OuterLetter)`
  color: ${(props) => props.theme.CENTER};
  animation: none;
  visibility: visible;
`;

const Letters = ({
  centerLetter,
  outerLetters,
  onClickLetter,
}: LettersProps) => {
  const [fadingOut, setFadingOut] = useState<boolean>(false);
  const [fadingIn, setFadingIn] = useState<boolean>(true);

  useEffect(() => {
    const shuffleListener = () => {
      setFadingOut(true);
      setFadingIn(false);
      setTimeout(() => {
        setFadingOut(false);
      }, FADE_OUT_DURATION);
    };

    subscribe("Shuffle", shuffleListener);

    return () => {
      unsubscribe("Shuffle", shuffleListener);
    };
  }, []);

  useEffect(() => {
    setFadingIn(true);
  }, [outerLetters]);

  return (
    <SectionContainer>
      <LetterRow>
        <LetterContainer>
          <OuterLetter
            className="letter"
            $fadingOut={fadingOut}
            $fadingIn={fadingIn}
            $fadeOrder={6}
            key={outerLetters[0]}
            onClick={() => onClickLetter(outerLetters[0])}
          >
            {outerLetters[0].toUpperCase()}
          </OuterLetter>
        </LetterContainer>
        <LetterContainer>
          <OuterLetter
            className="letter"
            $fadingOut={fadingOut}
            $fadingIn={fadingIn}
            $fadeOrder={1}
            key={outerLetters[1]}
            onClick={() => onClickLetter(outerLetters[1])}
          >
            {outerLetters[1].toUpperCase()}
          </OuterLetter>
        </LetterContainer>
      </LetterRow>
      <LetterRow>
        <LetterContainer>
          <OuterLetter
            className="letter"
            $fadingOut={fadingOut}
            $fadingIn={fadingIn}
            $fadeOrder={5}
            key={outerLetters[2]}
            onClick={() => onClickLetter(outerLetters[2])}
          >
            {outerLetters[2].toUpperCase()}
          </OuterLetter>
        </LetterContainer>
        <LetterContainer>
          <CenterLetter
            $fadingOut={fadingOut}
            $fadingIn={fadingIn}
            $fadeOrder={0}
            key={centerLetter}
            onClick={() => onClickLetter(centerLetter)}
          >
            {centerLetter.toUpperCase()}
          </CenterLetter>
        </LetterContainer>
        <LetterContainer>
          <OuterLetter
            $fadingOut={fadingOut}
            $fadingIn={fadingIn}
            $fadeOrder={2}
            key={outerLetters[3]}
            onClick={() => onClickLetter(outerLetters[3])}
          >
            {outerLetters[3].toUpperCase()}
          </OuterLetter>
        </LetterContainer>
      </LetterRow>
      <LetterRow>
        <LetterContainer>
          <OuterLetter
            $fadingOut={fadingOut}
            $fadingIn={fadingIn}
            $fadeOrder={4}
            key={outerLetters[4]}
            onClick={() => onClickLetter(outerLetters[4])}
          >
            {outerLetters[4].toUpperCase()}
          </OuterLetter>
        </LetterContainer>
        <LetterContainer>
          <OuterLetter
            $fadingOut={fadingOut}
            $fadingIn={fadingIn}
            $fadeOrder={3}
            key={outerLetters[5]}
            onClick={() => onClickLetter(outerLetters[5])}
          >
            {outerLetters[5].toUpperCase()}
          </OuterLetter>
        </LetterContainer>
      </LetterRow>
    </SectionContainer>
  );
};

export default Letters;
