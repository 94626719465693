import axios from "axios";
import { User, UserGuess } from "../models/User.model";

const SERVER = process.env.REACT_APP_SERVER_BASE;

export const getUsersByDate = async (date: string): Promise<User[]> => {
  const response = await axios.get<User[]>(`${SERVER}/users/${date}`);

  return response.data;
};

export const getUserById = async (userId: string): Promise<User> => {
  const response = await axios.get<User>(
    `${SERVER}/user/${encodeURIComponent(userId)}`
  );

  if (response.status === 404) throw new Error("User not found");
  else return response.data;
};

export const updateUser = async (payload: UserGuess): Promise<void> => {
  await axios.post(`${SERVER}/user`, payload);

  return;
};
