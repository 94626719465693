import styled from "styled-components";

export const Page = styled.div`
  font-size: 18px;
  font-family: monospace;
  text-align: center;
  color: ${(props) => props.theme.TEXT};
`;

export const RevealedAnswer = styled.div`
  height: 24px;
  line-height: 24px;
`;

export const RevealedPangram = styled(RevealedAnswer)`
  color: ${(props) => props.theme.PRIMARY};
  font-weight: bold;
`;

export const Button = styled.button`
  font-family: monospace;
  font-size: 18px;
  margin: 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: ${(props) => props.theme.TEXT};
  background-color: ${(props) => props.theme.SECONDARY};

  &:hover {
    background-color: ${(props) => props.theme.HIGHLIGHT};
  }
`;

export const LeaderboardDivider = styled.hr`
  background-color: ${(props) => props.theme.TEXT};
  border: none;
  height: 2px;
  margin: 11px 0px;
`;

export const UserCard = styled.div<{ width: number }>`
  min-width: ${({ width }) => width}px;
  flex-shrink: 0;
`;

export const LETTER_LENGTH = 10.83;

export type Tab = "homepage" | "theme" | "yesterday" | "badges";

export const Score = styled.span`
  color: ${(props) => props.theme.PRIMARY};
  background-color: ${(props) => props.theme.SECONDARY};
  padding: 2px 6px;
`;

export const UserCardTitle = styled.div<{ $self: boolean }>`
  color: ${(props) => props.$self && props.theme.PRIMARY};
`;
