export const getDateString = (date: Date): string => {
  date.setHours(date.getHours() - 3);
  const dateString = `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
  return dateString;
};

export const calculateScore = (
  answers: string[],
  pangrams: string[]
): number => {
  return answers
    .map((w) => {
      if (w.length === 4) return 1;
      else if (pangrams.includes(w)) return w.length + 7;
      else return w.length;
    })
    .reduce((a, b) => a + b, 0);
};
