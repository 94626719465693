import styled from "styled-components";
import { User } from "../models/User.model";
import { Button } from "./Common";

export type Badge =
  | "pangramStreak"
  | "reigningChamp"
  | "pioneer"
  | "linguist"
  | "perfectionist"
  | "mostCreative";

export type BadgeMetadata = {
  pioneerWords?: string[];
};

export const BadgeSection = styled.div`
  display: flex;
  justify-content: center;
  margin: 0px 10px;
  height: 34px;
`;

const BadgeContainer = styled.div<{ size: number }>`
  flex-shrink: 0;
  position: relative;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  margin: 2px;
`;

const BaseBadge = styled.div<{ $clickable: boolean }>`
  position: absolute;
  height: 100%;
  width: 100%;
  cursor: ${(props) => (props.$clickable ? "pointer" : "auto")};
`;

const BadgeBackground = styled(BaseBadge)`
  clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
  background-color: ${(props) => props.theme.PRIMARY};

  &:hover {
    background-color: ${(props) =>
      props.$clickable ? props.theme.HIGHLIGHT : ""};
  }
`;

const PangramStreakCount = styled.div<{ size: number }>`
  color: ${(props) => props.theme.BACKGROUND};
  line-height: ${(props) => props.size}px;
  font-size: ${(props) => props.size * 0.6}px;
`;

const LinguistBadgeTopHalf = styled(BaseBadge)`
  background-color: ${(props) => props.theme.BACKGROUND};
  clip-path: polygon(
    50% 20%,
    77% 35%,
    77% 65%,
    59% 65%,
    59% 45%,
    50% 40%,
    41% 45%,
    41% 65%,
    23% 65%,
    23% 35%
  );
`;

const LinguistBadgeBottomHalf = styled(BaseBadge)`
  background-color: ${(props) => props.theme.BACKGROUND};
  clip-path: polygon(
    50% 80%,
    77% 65%,
    77% 35%,
    59% 35%,
    59% 55%,
    50% 60%,
    41% 55%,
    41% 35%,
    23% 35%,
    23% 65%
  );
`;

const PioneerBadgePatternLeft = styled(BaseBadge)`
  background-color: ${(props) => props.theme.BACKGROUND};
  clip-path: polygon(23% 0%, 36% 0%, 36% 100%, 23% 100%);
`;

const PioneerBadgePatternRight = styled(BaseBadge)`
  background-color: ${(props) => props.theme.BACKGROUND};
  clip-path: polygon(77% 0%, 64% 0%, 64% 100%, 77% 100%);
`;

const ReigningChampBadgeCrown = styled(BaseBadge)`
  background-color: ${(props) => props.theme.BACKGROUND};
  clip-path: polygon(
    15% 35%,
    32.5% 50%,
    50% 35%,
    67.5% 50%,
    85% 35%,
    85% 65%,
    15% 65%
  );
`;

const PerfectionistStar = styled(BaseBadge)`
  background-color: ${(props) => props.theme.BACKGROUND};
  clip-path: polygon(
    50% 10%,
    57.84% 39.21%,
    88.04% 37.64%,
    62.68% 54.12%,
    73.51% 82.36%,
    50% 63.33%,
    26.49% 82.36%,
    37.32% 54.12%,
    11.96% 37.64%,
    42.16% 39.21%
  );
`;

const MostCreativePattern1 = styled(BaseBadge)`
  background-color: ${(props) => props.theme.BACKGROUND};
  clip-path: polygon(50% 25%, 77% 40%, 77% 50%, 50% 35%, 23% 50%, 23% 40%);
`;

const MostCreativePattern2 = styled(BaseBadge)`
  background-color: ${(props) => props.theme.BACKGROUND};
  clip-path: polygon(50% 45%, 77% 60%, 77% 70%, 50% 55%, 23% 70%, 23% 60%);
`;

const BadgeModalBadgeRow = styled.div`
  margin: 50px 0px;
  display: flex;
  justify-content: center;
`;

const BadgeTitle = styled.div`
  font-size: 30px;
`;

const BadgeDescription = styled.div`
  margin: 40px 20px 20px 20px;
  white-space: pre-wrap;
`;

const BadgeModalBottom = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
`;

const BadgeRequirement = styled.div`
  font-size: 14px;
`;

const BadgeModalCloseButton = styled(Button)`
  margin: 20px 0px;
`;

export const generateBadges = (
  user: User,
  allUsers: User[],
  pangrams: string[],
  toggleBadge: (badge: Badge, user: User, metadata: BadgeMetadata) => void
): JSX.Element[] => {
  const badges: JSX.Element[] = [];

  if (user.pangramStreak && user.answers.some((w) => pangrams.includes(w))) {
    badges.push(
      <BadgeContainer size={30} key={"pangramStreak"}>
        <BadgeBackground
          onClick={() => toggleBadge("pangramStreak", user, {})}
          $clickable
        >
          <PangramStreakCount size={30}>
            {user.pangramStreak}
          </PangramStreakCount>
        </BadgeBackground>
      </BadgeContainer>
    );
  }

  if (user.reigningChamp) {
    badges.push(
      <BadgeContainer size={30} key={"reigningChamp"}>
        <BadgeBackground
          onClick={() => toggleBadge("reigningChamp", user, {})}
          $clickable
        >
          <ReigningChampBadgeCrown $clickable />
        </BadgeBackground>
      </BadgeContainer>
    );
  }

  if (user.answers.length >= 5 && user.answers.every((a) => a.length >= 5)) {
    badges.push(
      <BadgeContainer size={30} key={"linguist"}>
        <BadgeBackground
          onClick={() => toggleBadge("linguist", user, {})}
          $clickable
        >
          <LinguistBadgeTopHalf $clickable />
          <LinguistBadgeBottomHalf $clickable />
        </BadgeBackground>
      </BadgeContainer>
    );
  }

  if (allUsers.filter((u) => u.answers.length > 0).length >= 5) {
    const uniqueWords = user.answers.filter((w) =>
      allUsers
        .filter((u) => u.userId !== user.userId)
        .every((u) => !u.answers.includes(w))
    );

    if (uniqueWords.length > 0) {
      badges.push(
        <BadgeContainer size={30} key={"pioneer"}>
          <BadgeBackground
            onClick={() =>
              toggleBadge("pioneer", user, {
                pioneerWords: uniqueWords,
              })
            }
            $clickable
          >
            <PioneerBadgePatternLeft $clickable />
            <PioneerBadgePatternRight $clickable />
          </BadgeBackground>
        </BadgeContainer>
      );
    }
  }

  if (user.guessCount === user.answers.length && user.answers.length >= 5) {
    badges.push(
      <BadgeContainer size={30} key={"perfectionist"}>
        <BadgeBackground
          onClick={() => toggleBadge("perfectionist", user, {})}
          $clickable
        >
          <PerfectionistStar $clickable />
        </BadgeBackground>
      </BadgeContainer>
    );
  }

  // TODO: The "u.guessCount || 0" is just needed for the first day when some users will have undefined guess counts, can be turned off afterwards
  const mostIncorrectGuesses = Math.max(
    ...allUsers
      .filter((u) => u.answers.length > 0)
      .map((u) => (u.guessCount || 0) - u.answers.length)
  );
  if (
    user.guessCount - user.answers.length === mostIncorrectGuesses &&
    allUsers.filter((u) => u.answers.length > 0).length >= 5
  ) {
    badges.push(
      <BadgeContainer size={30} key={"mostCreative"}>
        <BadgeBackground
          onClick={() => toggleBadge("mostCreative", user, {})}
          $clickable
        >
          <MostCreativePattern1 $clickable />
          <MostCreativePattern2 $clickable />
        </BadgeBackground>
      </BadgeContainer>
    );
  }

  return badges;
};

export const generateBadgesForYesterday = (
  user: User,
  allUsers: User[],
  pangrams: string[]
): JSX.Element[] => {
  const badges: JSX.Element[] = [];

  if (user.pangramStreak && user.answers.some((w) => pangrams.includes(w))) {
    badges.push(
      <BadgeContainer size={30} key={"pangramStreak"}>
        <BadgeBackground $clickable={false}>
          <PangramStreakCount size={30}>
            {user.pangramStreak}
          </PangramStreakCount>
        </BadgeBackground>
      </BadgeContainer>
    );
  }

  if (user.reigningChamp) {
    badges.push(
      <BadgeContainer size={30} key={"reigningChamp"}>
        <BadgeBackground $clickable={false}>
          <ReigningChampBadgeCrown $clickable={false} />
        </BadgeBackground>
      </BadgeContainer>
    );
  }

  if (user.answers.length >= 5 && user.answers.every((a) => a.length >= 5)) {
    badges.push(
      <BadgeContainer size={30} key={"linguist"}>
        <BadgeBackground $clickable={false}>
          <LinguistBadgeTopHalf $clickable={false} />
          <LinguistBadgeBottomHalf $clickable={false} />
        </BadgeBackground>
      </BadgeContainer>
    );
  }

  if (
    allUsers.filter((u) => u.answers.length > 0).length >= 5 &&
    user.answers.some((w) =>
      allUsers
        .filter((u) => u.userId !== user.userId)
        .every((u) => !u.answers.includes(w))
    )
  ) {
    badges.push(
      <BadgeContainer size={30} key={"pioneer"}>
        <BadgeBackground $clickable={false}>
          <PioneerBadgePatternLeft $clickable={false} />
          <PioneerBadgePatternRight $clickable={false} />
        </BadgeBackground>
      </BadgeContainer>
    );
  }

  if (user.guessCount === user.answers.length && user.answers.length >= 5) {
    badges.push(
      <BadgeContainer size={30} key={"perfectionist"}>
        <BadgeBackground $clickable={false}>
          <PerfectionistStar $clickable={false} />
        </BadgeBackground>
      </BadgeContainer>
    );
  }

  // TODO: The "u.guessCount || 0" is just needed for the first day when some users will have undefined guess counts, can be turned off afterwards
  const mostIncorrectGuesses = Math.max(
    ...allUsers
      .filter((u) => u.answers.length > 0)
      .map((u) => (u.guessCount || 0) - u.answers.length)
  );
  if (
    user.guessCount - user.answers.length === mostIncorrectGuesses &&
    allUsers.filter((u) => u.answers.length > 0).length >= 5
  ) {
    badges.push(
      <BadgeContainer size={30} key={"mostCreative"}>
        <BadgeBackground $clickable={false}>
          <MostCreativePattern1 $clickable={false} />
          <MostCreativePattern2 $clickable={false} />
        </BadgeBackground>
      </BadgeContainer>
    );
  }

  return badges;
};

export const generateBadgeModal = (
  badge: Badge,
  user: User,
  activeUserId: string,
  metadata: BadgeMetadata,
  closeBadgeModal: () => void
): JSX.Element => {
  let badgeIcon, badgeTitle, badgeDescription, badgeRequirement;

  switch (badge) {
    case "pangramStreak":
      badgeIcon = (
        <BadgeContainer size={90}>
          <BadgeBackground $clickable={false}>
            <PangramStreakCount size={90}>
              {user.pangramStreak}
            </PangramStreakCount>
          </BadgeBackground>
        </BadgeContainer>
      );
      badgeTitle = "Pangram Streak";
      badgeDescription = `${user.userId} has gotten a pangram ${
        user.pangramStreak
      } ${user.pangramStreak > 1 ? "days" : "day"} in a row!`;
      break;
    case "reigningChamp":
      badgeIcon = (
        <BadgeContainer size={90}>
          <BadgeBackground $clickable={false}>
            <ReigningChampBadgeCrown $clickable={false} />
          </BadgeBackground>
        </BadgeContainer>
      );
      badgeTitle = "Reigning Champ";
      badgeDescription = `${user.userId} had the highest score yesterday!`;
      break;
    case "linguist":
      badgeIcon = (
        <BadgeContainer size={90}>
          <BadgeBackground $clickable={false}>
            <LinguistBadgeTopHalf $clickable />
            <LinguistBadgeBottomHalf $clickable />
          </BadgeBackground>
        </BadgeContainer>
      );
      badgeTitle = "Linguist";
      badgeDescription = `All of ${user.userId}'s words are at least 5 letters long!`;
      badgeRequirement = "Minimum 5 words";
      break;
    case "pioneer":
      badgeIcon = (
        <BadgeContainer size={90}>
          <BadgeBackground $clickable={false}>
            <PioneerBadgePatternLeft $clickable={false} />
            <PioneerBadgePatternRight $clickable={false} />
          </BadgeBackground>
        </BadgeContainer>
      );
      const uniqueWords = metadata.pioneerWords || []; // should never be underfined but just in case
      const wordCount = uniqueWords.length;
      badgeTitle = "Pioneer";
      badgeDescription = `${user.userId} has ${wordCount} ${
        wordCount > 1 ? "words" : "word"
      } that nobody else has!`;
      if (user.userId === activeUserId) {
        const revealedUniqueWords = "\n(" + uniqueWords.join(", ") + ")";
        badgeDescription += revealedUniqueWords.toUpperCase();
      }
      badgeRequirement = "Minimum 4 other users";
      break;
    case "perfectionist":
      badgeIcon = (
        <BadgeContainer size={90}>
          <BadgeBackground $clickable={false}>
            <PerfectionistStar $clickable={false} />
          </BadgeBackground>
        </BadgeContainer>
      );
      badgeTitle = "Perfectionist";
      badgeDescription = `${user.userId} has had no incorrect guesses!`;
      badgeRequirement = "Minimum 5 words";
      break;
    case "mostCreative":
      badgeIcon = (
        <BadgeContainer size={90}>
          <BadgeBackground $clickable={false}>
            <MostCreativePattern1 $clickable={false} />
            <MostCreativePattern2 $clickable={false} />
          </BadgeBackground>
        </BadgeContainer>
      );
      badgeTitle = "Most Creative";
      badgeDescription = `${user.userId} has submitted the most incorrect guesses!`;
      badgeRequirement = "Minimum 4 other users, must be valid guesses";
      break;
  }

  return (
    <>
      <BadgeModalBadgeRow>{badgeIcon}</BadgeModalBadgeRow>
      <BadgeTitle>{badgeTitle}</BadgeTitle>
      <BadgeDescription>{badgeDescription}</BadgeDescription>
      <BadgeModalBottom>
        {badgeRequirement && (
          <BadgeRequirement>{badgeRequirement}</BadgeRequirement>
        )}
        <BadgeModalCloseButton onClick={() => closeBadgeModal()}>
          Close
        </BadgeModalCloseButton>
      </BadgeModalBottom>
    </>
  );
};

export const BadgeWorkshopTab = () => {
  return (
    <div>
      <br />
      <br />
      <br />
      <div style={{ display: "flex" }}>
        <BadgeContainer size={30}>
          <BadgeBackground $clickable={false}>
            <PangramStreakCount size={30}>
              {Math.floor(Math.random() * 20)}
            </PangramStreakCount>
          </BadgeBackground>
        </BadgeContainer>
        <BadgeContainer size={30}>
          <BadgeBackground $clickable={false}>
            <ReigningChampBadgeCrown $clickable={false} />
          </BadgeBackground>
        </BadgeContainer>
        <BadgeContainer size={30}>
          <BadgeBackground $clickable={false}>
            <LinguistBadgeTopHalf $clickable />
            <LinguistBadgeBottomHalf $clickable />
          </BadgeBackground>
        </BadgeContainer>
        <BadgeContainer size={30}>
          <BadgeBackground $clickable={false}>
            <PioneerBadgePatternLeft $clickable={false} />
            <PioneerBadgePatternRight $clickable={false} />
          </BadgeBackground>
        </BadgeContainer>
        <BadgeContainer size={30}>
          <BadgeBackground $clickable={false}>
            <PerfectionistStar $clickable={false} />
          </BadgeBackground>
        </BadgeContainer>
        <BadgeContainer size={30}>
          <BadgeBackground $clickable={false}>
            <MostCreativePattern1 $clickable={false} />
            <MostCreativePattern2 $clickable={false} />
          </BadgeBackground>
        </BadgeContainer>
      </div>
    </div>
  );
};
