import { styled } from "styled-components";
import { EventLogMessage } from "../models/EventLogMessage.model";
import { useContext } from "react";
import { ActiveUserContext } from "../utils/contexts";
import { Username } from "./Header";

interface EventLogProps {
  events: EventLogMessage[];
}

const EventLogContainer = styled.div`
  margin: 40px 0px 20px 0px;
  padding: 0px 10px;
`;

const EventLogContent = styled.div`
  padding: 10px;
  background-color: ${(props) => props.theme.SECONDARY};
  display: inline-block;
  width: calc(100% - 20px);
  max-width: 480px;
  text-align: left;
`;

const Message = styled.div`
  line-height: 28px;
  margin: 10px 10px;
`;

const TimeStamp = styled.div`
  display: inline-block;
  color: ${(props) => props.theme.HIGHLIGHT};
`;

const EventLog = ({ events }: EventLogProps) => {
  const userId = useContext(ActiveUserContext);

  const buildMessageDisplay = (
    message: string,
    userIdInsertions: string[]
  ): JSX.Element[] => {
    const PLACEHOLDER = "PLAYER";
    const result: JSX.Element[] = [];

    let stringIndex = 0;
    for (let i = 0; i < userIdInsertions.length; i++) {
      const beginning = message.substring(
        stringIndex,
        message.indexOf(PLACEHOLDER, stringIndex)
      );
      const formattedUserId = <Username>{userIdInsertions[i]}</Username>;
      result.push(<>{beginning}</>);
      result.push(formattedUserId);

      stringIndex += beginning.length + PLACEHOLDER.length;
    }

    const ending = message.substring(stringIndex);
    result.push(<>{ending}</>);

    return result;
  };

  return (
    <EventLogContainer>
      <EventLogContent>
        {events.map((m) => (
          <Message>
            <TimeStamp>
              {new Date(m.timestamp)
                .toLocaleTimeString([], {
                  timeStyle: "short",
                })
                .replace(/ /g, "")}
            </TimeStamp>{" "}
            {buildMessageDisplay(m.message, m.userIdInsertions)}
            {m.userSpecificContent[userId] &&
              ` ${m.userSpecificContent[userId]}`}
          </Message>
        ))}
      </EventLogContent>
    </EventLogContainer>
  );
};

export default EventLog;
