import { styled, keyframes, css } from "styled-components";
import { User } from "../models/User.model";
import {
  LETTER_LENGTH,
  LeaderboardDivider,
  RevealedAnswer,
  RevealedPangram,
  Score,
  UserCard,
  UserCardTitle,
} from "./Common";
import { useEffect, useState, useContext } from "react";
import { calculateScore } from "../models/Utils";
import {
  Badge,
  BadgeSection,
  BadgeMetadata,
  generateBadgeModal,
  generateBadges,
} from "./Badges";
import { BADGE_MODAL_FADE_DURATION } from "../models/Constants";
import { ActiveUserContext } from "../utils/contexts";

interface LeaderboardProps {
  userId: string;
  activeUsers: User[];
  revealedWords: string[];
  pangrams: string[];
  answers: string[];
}

const LeaderboardContainer = styled.div<{ width: number }>`
  margin-top: 50px;
  display: flex;
  overflow-x: auto;
  margin-bottom: 10px;
  justify-content: ${({ width }) =>
    window.innerWidth > width ? "center" : "left"};
`;

const HiddenAnswer = styled.div<{ length: number }>`
  width: ${({ length }) => length * LETTER_LENGTH}px;
  height: 24px;
  background-color: ${(props) => props.theme.TEXT};
  margin: auto;
`;

const HiddenPangram = styled(HiddenAnswer)`
  background-color: ${(props) => props.theme.PRIMARY};
`;

const MissingAnswer = styled(RevealedAnswer)`
  color: ${(props) => props.theme.HIGHLIGHT};
`;

const BadgeModalBackgroundFadeIn = keyframes`
  0% { background-color: rgb(0,0,0,0) }
  100% { background-color: rgb(0,0,0,0.8) }
`;

const BadgeModalBackgroundFadeOut = keyframes`
  0% { background-color: rgb(0,0,0,0.8) }
  100% { background-color: rgb(0,0,0,0) }
`;

const BadgeModalContainerFadeIn = keyframes`
  0% { opacity: 0 }
  100% { opacity: 100 }
`;

const BadgeModalContainerFadeOut = keyframes`
  0% { opacity: 100 }
  100% { opacity: 0 }
`;

const BadgeModalBackground = styled.div<{ $fadingOut: boolean }>`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;

  animation: ${({ $fadingOut: fadingOut }) => {
    if (!fadingOut) {
      return css`
        ${BADGE_MODAL_FADE_DURATION}ms ${BadgeModalBackgroundFadeIn} forwards
      `;
    } else {
      return css`
        ${BADGE_MODAL_FADE_DURATION}ms ${BadgeModalBackgroundFadeOut} forwards
      `;
    }
  }};
`;

const BadgeModalContainer = styled.div<{ $fadingOut: boolean }>`
  width: 300px;
  height: 500px;
  left: ${(window.innerWidth - 300) / 2 - 8}px;
  top: ${(window.innerHeight - 500) / 2 - 8}px;

  position: fixed;
  background-color: ${(props) => props.theme.BACKGROUND};
  border: 8px solid ${(props) => props.theme.PRIMARY};
  border-radius: 10px;
  z-index: 3;
  cursor: auto;

  animation: ${({ $fadingOut: fadingOut }) => {
    if (!fadingOut) {
      return css`
        ${BADGE_MODAL_FADE_DURATION}ms ${BadgeModalContainerFadeIn} forwards
      `;
    } else {
      return css`
        ${BADGE_MODAL_FADE_DURATION}ms ${BadgeModalContainerFadeOut} forwards
      `;
    }
  }};
`;

const Leaderboard = ({
  userId,
  activeUsers,
  revealedWords,
  pangrams,
  answers,
}: LeaderboardProps) => {
  const [userCardWidth, setUserCardWidth] = useState<number | null>(null);
  const [badgeModalInfo, setBadgeModalInfo] = useState<{
    badge: Badge | null;
    user: User | null;
    metadata: BadgeMetadata;
  }>({ badge: null, user: null, metadata: {} });
  const [badgeModalFadeOut, setBadgeModalFadeOut] = useState<boolean>(false);
  const activeUserId = useContext(ActiveUserContext);

  const toggleBadge = (badge: Badge, user: User, metadata: BadgeMetadata) => {
    setBadgeModalInfo({ badge, user, metadata });
  };

  const closeBadgeModal = () => {
    setBadgeModalFadeOut(true);
    setTimeout(() => {
      setBadgeModalInfo({ badge: null, user: null, metadata: {} });
      setBadgeModalFadeOut(false);
    }, BADGE_MODAL_FADE_DURATION);
  };

  const handleBadgeModalClick: React.MouseEventHandler<HTMLDivElement> = (
    e
  ) => {
    e.stopPropagation();
  };

  useEffect(() => {
    const maxWordLength = Math.max(...answers.map((a) => a.length));
    setUserCardWidth(maxWordLength * LETTER_LENGTH + 20);
  }, [answers]);

  return userCardWidth ? (
    <LeaderboardContainer width={userCardWidth * activeUsers.length}>
      {badgeModalInfo.badge && badgeModalInfo.user && (
        <BadgeModalBackground
          onClick={() => closeBadgeModal()}
          $fadingOut={badgeModalFadeOut}
        >
          <BadgeModalContainer
            onClick={handleBadgeModalClick}
            $fadingOut={badgeModalFadeOut}
          >
            {generateBadgeModal(
              badgeModalInfo.badge,
              badgeModalInfo.user,
              activeUserId,
              badgeModalInfo.metadata,
              closeBadgeModal
            )}
          </BadgeModalContainer>
        </BadgeModalBackground>
      )}
      {activeUsers
        .sort(
          (u1, u2) =>
            calculateScore(u2.answers, pangrams) -
            calculateScore(u1.answers, pangrams)
        )
        .map((u) => (
          <UserCard key={u.userId} width={userCardWidth}>
            <UserCardTitle $self={u.userId === userId}>
              {u.userId}
            </UserCardTitle>
            <LeaderboardDivider />
            <Score>{calculateScore(u.answers, pangrams)}</Score>
            <LeaderboardDivider />
            <BadgeSection>
              {generateBadges(u, activeUsers, pangrams, toggleBadge)}
            </BadgeSection>
            <LeaderboardDivider />
            {u.answers.map((a) =>
              revealedWords.includes(a) ? (
                pangrams.includes(a) ? (
                  <RevealedPangram key={a}>{a.toUpperCase()}</RevealedPangram>
                ) : (
                  <RevealedAnswer key={a}>{a.toUpperCase()}</RevealedAnswer>
                )
              ) : pangrams.includes(a) ? (
                <HiddenPangram key={a} length={a.length} />
              ) : (
                <HiddenAnswer key={a} length={a.length} />
              )
            )}
            <LeaderboardDivider />
            {revealedWords
              .filter((w) => !u.answers.includes(w))
              .map((w) =>
                pangrams.includes(w) ? (
                  <RevealedPangram key={w}>{w.toUpperCase()}</RevealedPangram>
                ) : (
                  <MissingAnswer key={w}>{w.toUpperCase()}</MissingAnswer>
                )
              )}
          </UserCard>
        ))}
    </LeaderboardContainer>
  ) : (
    <div>Loading...</div>
  );
};

export default Leaderboard;
