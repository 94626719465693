import styled from "styled-components";
import { Puzzle } from "../models/Puzzle.model";
import { calculateScore } from "../models/Utils";
import { memo, useCallback } from "react";

interface ScoreSliderProps {
  puzzleData: Puzzle;
  revealedWords: string[];
}

const PERCENTILE_RANKS = [2, 5, 8, 15, 25, 40, 50, 70, 100];

const ScoreSliderContainer = styled.div`
  height: 44px;
`;

const ScoreSliderDot = styled.div`
  display: inline-block;
  vertical-align: top;
  background-color: ${(props) => props.theme.PRIMARY};
  height: 12px;
  width: 12px;
  margin-top: 16px;
  border-radius: 2px;
`;

const ScoreSliderSegment = styled.div`
  display: inline-block;
  vertical-align: top;
  background-color: ${(props) => props.theme.PRIMARY};
  height: 2px;
  width: 24px;
  margin-top: 21px;
`;

const ScoreSliderValue = styled.div`
  display: inline-block;
  vertical-align: top;
  background-color: ${(props) => props.theme.PRIMARY};
  color: ${(props) => props.theme.SECONDARY};
  height: 36px;
  line-height: 36px;
  width: 36px;
  padding: 4px;
  border-radius: 5px;
`;

const ScoreSlider = memo(({ puzzleData, revealedWords }: ScoreSliderProps) => {
  const generateScoreSlider = useCallback((): JSX.Element[] => {
    if (!puzzleData) return [];

    const sliderComponents = [
      <ScoreSliderDot key="0" />,
      <ScoreSliderSegment key="1" />,
      <ScoreSliderDot key="2" />,
      <ScoreSliderSegment key="3" />,
      <ScoreSliderDot key="4" />,
      <ScoreSliderSegment key="5" />,
      <ScoreSliderDot key="6" />,
      <ScoreSliderSegment key="7" />,
      <ScoreSliderDot key="8" />,
      <ScoreSliderSegment key="9" />,
      <ScoreSliderDot key="10" />,
      <ScoreSliderSegment key="11" />,
      <ScoreSliderDot key="12" />,
      <ScoreSliderSegment key="13" />,
      <ScoreSliderDot key="14" />,
      <ScoreSliderSegment key="15" />,
      <ScoreSliderDot key="16" />,
    ];

    const totalScore = calculateScore(puzzleData.answers, puzzleData.pangrams);
    const scoreThresholds = PERCENTILE_RANKS.map((p) =>
      Math.round((totalScore * p) / 100)
    );
    const currentScore = calculateScore(revealedWords, puzzleData.pangrams);
    let rankIndex = scoreThresholds.findIndex((n) => n > currentScore);

    if (currentScore === totalScore) {
      // Queen Bee case
      rankIndex = scoreThresholds.length - 1;
    }

    sliderComponents[rankIndex * 2] = (
      <ScoreSliderValue key={`${rankIndex * 2}`}>
        {currentScore}
      </ScoreSliderValue>
    );

    return sliderComponents;
  }, [puzzleData, revealedWords]);

  return <ScoreSliderContainer>{generateScoreSlider()}</ScoreSliderContainer>;
});

export default ScoreSlider;
