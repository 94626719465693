export interface Theme {
  name: string;
  PRIMARY: string;
  SECONDARY: string;
  CENTER: string;
  HIGHLIGHT: string;
  DISABLED: string;
  TEXT: string;
  BACKGROUND: string;
}

export const generateRandomTheme = (): Theme => {
  return {
    name: "True Random",
    PRIMARY: `rgb(${Math.floor(Math.random() * 256)},${Math.floor(
      Math.random() * 256
    )},${Math.floor(Math.random() * 256)})`,
    SECONDARY: `rgb(${Math.floor(Math.random() * 256)},${Math.floor(
      Math.random() * 256
    )},${Math.floor(Math.random() * 256)})`,
    CENTER: `rgb(${Math.floor(Math.random() * 256)},${Math.floor(
      Math.random() * 256
    )},${Math.floor(Math.random() * 256)})`,
    HIGHLIGHT: `rgb(${Math.floor(Math.random() * 256)},${Math.floor(
      Math.random() * 256
    )},${Math.floor(Math.random() * 256)})`,
    DISABLED: `rgb(${Math.floor(Math.random() * 256)},${Math.floor(
      Math.random() * 256
    )},${Math.floor(Math.random() * 256)})`,
    TEXT: `rgb(${Math.floor(Math.random() * 256)},${Math.floor(
      Math.random() * 256
    )},${Math.floor(Math.random() * 256)})`,
    BACKGROUND: `rgb(${Math.floor(Math.random() * 256)},${Math.floor(
      Math.random() * 256
    )},${Math.floor(Math.random() * 256)})`,
  };
};

export const generateLightTheme = (): Theme => {
  const scalars = [0, 190, 255, 128, 169, 51, 210];

  const baseColor = [
    Math.floor(Math.random() * 256),
    Math.floor(Math.random() * 256),
    Math.floor(Math.random() * 256),
  ];

  let palette = [];

  for (const v of scalars) {
    const weight = v / 255;
    const [r, g, b] = baseColor;
    const newR = (255 - r) * weight + r;
    const newG = (255 - g) * weight + g;
    const newB = (255 - b) * weight + b;

    palette.push(
      `#${Math.round(newR).toString(16).padStart(2, "0")}${Math.round(newG)
        .toString(16)
        .padStart(2, "0")}${Math.round(newB).toString(16).padStart(2, "0")}`
    );
  }

  return {
    name: "Random Light",
    PRIMARY: palette[0],
    SECONDARY: palette[1],
    CENTER: palette[2],
    HIGHLIGHT: palette[3],
    DISABLED: palette[4],
    TEXT: palette[5],
    BACKGROUND: palette[6],
  };
};

export const generateDarkTheme = (): Theme => {
  const scalars = [0, 190, 150, 128, 169, 51, 210];

  const baseColor = [
    Math.floor(Math.random() * 256),
    Math.floor(Math.random() * 256),
    Math.floor(Math.random() * 256),
  ];

  let palette = [];

  for (const v of scalars) {
    const weight = v / 255;
    const [r, g, b] = baseColor;
    const newR = r - r * weight;
    const newG = g - g * weight;
    const newB = b - b * weight;

    palette.push(
      `#${Math.round(newR).toString(16).padStart(2, "0")}${Math.round(newG)
        .toString(16)
        .padStart(2, "0")}${Math.round(newB).toString(16).padStart(2, "0")}`
    );
  }

  const centerWeight = scalars[2] / 255;

  const [r, g, b] = baseColor;
  const centerR = r + (255 - r) * centerWeight;
  const centerG = g + (255 - g) * centerWeight;
  const centerB = b + (255 - b) * centerWeight;

  palette[2] = `#${Math.round(centerR)
    .toString(16)
    .padStart(2, "0")}${Math.round(centerG)
    .toString(16)
    .padStart(2, "0")}${Math.round(centerB).toString(16).padStart(2, "0")}`;

  return {
    name: "Random Dark",
    PRIMARY: palette[0],
    SECONDARY: palette[1],
    CENTER: palette[2],
    HIGHLIGHT: palette[3],
    DISABLED: palette[4],
    TEXT: palette[5],
    BACKGROUND: palette[6],
  };
};

export const THEMES = [
  {
    name: "Classic",
    PRIMARY: "#F06060",
    SECONDARY: "#F2EBBF",
    CENTER: "#5C4B51",
    HIGHLIGHT: "#8CBEB2",
    DISABLED: "#D3D3D3",
    TEXT: "black",
    BACKGROUND: "white",
  },
  {
    name: "Noir",
    PRIMARY: "#000000",
    SECONDARY: "#bebebe",
    CENTER: "#ffffff",
    HIGHLIGHT: "#808080",
    DISABLED: "#a9a9a9",
    TEXT: "#333333",
    BACKGROUND: "#d2d2d2",
  },
  {
    name: "Nord Light",
    PRIMARY: "#88c0d0",
    SECONDARY: "#5e81ac",
    CENTER: "#b48ead",
    HIGHLIGHT: "#a3be8c",
    DISABLED: "#d8dee9",
    TEXT: "#2e3440",
    BACKGROUND: "#eceff4",
  },
  {
    name: "Nord Dark",
    PRIMARY: "#88c0d0",
    SECONDARY: "#5e81ac",
    CENTER: "#b48ead",
    HIGHLIGHT: "#a3be8c",
    DISABLED: "#81a1c1",
    TEXT: "#eceff4",
    BACKGROUND: "#4c566a",
  },
  {
    name: "Bubblegum",
    PRIMARY: "#F46197",
    SECONDARY: "#FFFDF7",
    CENTER: "#8A84E2",
    HIGHLIGHT: "#754668",
    DISABLED: "#9D96B8",
    TEXT: "black",
    BACKGROUND: "#FAAFC7",
  },
  {
    name: "Ugly",
    PRIMARY: "darkslateblue",
    SECONDARY: "darkkhaki",
    CENTER: "darkmagenta",
    HIGHLIGHT: "crimson",
    DISABLED: "lightgrey",
    TEXT: "black",
    BACKGROUND: "white",
  },
  {
    name: "Desert",
    PRIMARY: "#F27141",
    SECONDARY: "#F2BE5C",
    CENTER: "white",
    HIGHLIGHT: "#F2C2CF",
    DISABLED: "lightgrey",
    TEXT: "black",
    BACKGROUND: "#F1F2D5",
  },
  {
    name: "Electric",
    PRIMARY: "#FF005A",
    SECONDARY: "#FF9800",
    CENTER: "#FFCE00",
    HIGHLIGHT: "#AD0626",
    DISABLED: "#0FA3B1",
    TEXT: "white",
    BACKGROUND: "#3D182B",
  },
  {
    name: "Ocean",
    PRIMARY: "#99B7F9",
    SECONDARY: "#273273",
    CENTER: "white",
    HIGHLIGHT: "#829FD9",
    DISABLED: "darkslateblue",
    TEXT: "white",
    BACKGROUND: "#131A40",
  },
  {
    name: "Batman",
    PRIMARY: "#FDFF00",
    SECONDARY: "#282E3C",
    CENTER: "white",
    HIGHLIGHT: "#505C7C",
    DISABLED: "#988829",
    TEXT: "white",
    BACKGROUND: "#242424",
  },
  {
    name: "Toy Piano",
    PRIMARY: "#BF0B3B",
    SECONDARY: "#29A632",
    CENTER: "#F28B0C",
    HIGHLIGHT: "#D50BD9",
    DISABLED: "yellow",
    TEXT: "black",
    BACKGROUND: "white",
  },
  {
    name: "Navy",
    PRIMARY: "#F22222",
    SECONDARY: "#0A4B73",
    CENTER: "white",
    HIGHLIGHT: "#0F8DBF",
    DISABLED: "#59122C",
    TEXT: "white",
    BACKGROUND: "#011826",
  },
  {
    name: "Halloween",
    PRIMARY: "white",
    SECONDARY: "#F26430",
    CENTER: "black",
    HIGHLIGHT: "#731B07",
    DISABLED: "#400D01",
    TEXT: "#BF3C1F",
    BACKGROUND: "black",
  },
  {
    name: "Sea Foam",
    PRIMARY: "#5DABA9",
    SECONDARY: "#AAE8C2",
    CENTER: "#CB98F0",
    HIGHLIGHT: "#FFA1D3",
    DISABLED: "#92A3FF",
    TEXT: "black",
    BACKGROUND: "white",
  },
  {
    name: "Fanta",
    PRIMARY: "red",
    SECONDARY: "orange",
    CENTER: "yellow",
    HIGHLIGHT: "green",
    DISABLED: "blue",
    TEXT: "indigo",
    BACKGROUND: "white",
  },
  {
    name: "Red Light",
    PRIMARY: "#d70015",
    SECONDARY: "#f5bec3",
    CENTER: "#ffffff",
    HIGHLIGHT: "#eb808a",
    DISABLED: "#f2a9b0",
    TEXT: "#df3344",
    BACKGROUND: "#f8d2d6",
  },
  {
    name: "Red Dark",
    PRIMARY: "#ff6961",
    SECONDARY: "#411b19",
    CENTER: "#ffc1be",
    HIGHLIGHT: "#7f3430",
    DISABLED: "#562321",
    TEXT: "#cc544e",
    BACKGROUND: "#2d1311",
  },
  {
    name: "Orange Light",
    PRIMARY: "#c93400",
    SECONDARY: "#f1cbbe",
    CENTER: "#ffffff",
    HIGHLIGHT: "#e49a80",
    DISABLED: "#edbba9",
    TEXT: "#d45d33",
    BACKGROUND: "#f5dbd2",
  },
  {
    name: "Orange Dark",
    PRIMARY: "#ffb340",
    SECONDARY: "#412e10",
    CENTER: "#ffe0b0",
    HIGHLIGHT: "#7f5920",
    DISABLED: "#563c16",
    TEXT: "#cc8f33",
    BACKGROUND: "#2d200b",
  },
  {
    name: "Yellow Light",
    PRIMARY: "#b25000",
    SECONDARY: "#ebd2be",
    CENTER: "#ffffff",
    HIGHLIGHT: "#d9a880",
    DISABLED: "#e5c4a9",
    TEXT: "#c17333",
    BACKGROUND: "#f1e0d2",
  },
  {
    name: "Yellow Dark",
    PRIMARY: "#ffd426",
    SECONDARY: "#41360a",
    CENTER: "#ffeda6",
    HIGHLIGHT: "#7f6a13",
    DISABLED: "#56470d",
    TEXT: "#ccaa1e",
    BACKGROUND: "#2d2507",
  },
  {
    name: "Green Light",
    PRIMARY: "#248a3d",
    SECONDARY: "#c7e1ce",
    CENTER: "#ffffff",
    HIGHLIGHT: "#92c59e",
    DISABLED: "#b5d8be",
    TEXT: "#50a164",
    BACKGROUND: "#d8eadd",
  },
  {
    name: "Green Dark",
    PRIMARY: "#30db5b",
    SECONDARY: "#0c3817",
    CENTER: "#aaf0bb",
    HIGHLIGHT: "#186d2d",
    DISABLED: "#104a1f",
    TEXT: "#26af49",
    BACKGROUND: "#082710",
  },
  {
    name: "Mint Light",
    PRIMARY: "#0c817b",
    SECONDARY: "#c1dfdd",
    CENTER: "#ffffff",
    HIGHLIGHT: "#86c0bd",
    DISABLED: "#add5d2",
    TEXT: "#3d9a95",
    BACKGROUND: "#d4e9e8",
  },
  {
    name: "Mint Dark",
    PRIMARY: "#66d4cf",
    SECONDARY: "#1a3635",
    CENTER: "#c0edeb",
    HIGHLIGHT: "#336a67",
    DISABLED: "#224746",
    TEXT: "#52aaa6",
    BACKGROUND: "#122525",
  },
  {
    name: "Teal Light",
    PRIMARY: "#008299",
    SECONDARY: "#bedfe5",
    CENTER: "#ffffff",
    HIGHLIGHT: "#80c1cc",
    DISABLED: "#a9d5dd",
    TEXT: "#339bad",
    BACKGROUND: "#d2e9ed",
  },
  {
    name: "Teal Dark",
    PRIMARY: "#5de6ff",
    SECONDARY: "#183b41",
    CENTER: "#bcf5ff",
    HIGHLIGHT: "#2e737f",
    DISABLED: "#1f4e56",
    TEXT: "#4ab8cc",
    BACKGROUND: "#10292d",
  },
  {
    name: "Cyan Light",
    PRIMARY: "#0071a4",
    SECONDARY: "#bedbe8",
    CENTER: "#ffffff",
    HIGHLIGHT: "#80b8d2",
    DISABLED: "#a9cfe0",
    TEXT: "#338db6",
    BACKGROUND: "#d2e6ef",
  },
  {
    name: "Cyan Dark",
    PRIMARY: "#70d7ff",
    SECONDARY: "#1d3741",
    CENTER: "#c4efff",
    HIGHLIGHT: "#386b7f",
    DISABLED: "#264956",
    TEXT: "#5aaccc",
    BACKGROUND: "#14262d",
  },
  {
    name: "Blue Light",
    PRIMARY: "#0040dd",
    SECONDARY: "#becef6",
    CENTER: "#ffffff",
    HIGHLIGHT: "#80a0ee",
    DISABLED: "#a9bff4",
    TEXT: "#3366e4",
    BACKGROUND: "#d2ddf9",
  },
  {
    name: "Blue Dark",
    PRIMARY: "#409cff",
    SECONDARY: "#102841",
    CENTER: "#b0d6ff",
    HIGHLIGHT: "#204e7f",
    DISABLED: "#163556",
    TEXT: "#337dcc",
    BACKGROUND: "#0b1c2d",
  },
  {
    name: "Indigo Light",
    PRIMARY: "#3634a3",
    SECONDARY: "#cccbe8",
    CENTER: "#ffffff",
    HIGHLIGHT: "#9b9ad1",
    DISABLED: "#bbbbe0",
    TEXT: "#5e5db5",
    BACKGROUND: "#dcdbef",
  },

  {
    name: "Indigo Dark",
    PRIMARY: "#7d7aff",
    SECONDARY: "#201f41",
    CENTER: "#c9c8ff",
    HIGHLIGHT: "#3e3d7f",
    DISABLED: "#2a2956",
    TEXT: "#6462cc",
    BACKGROUND: "#16162d",
  },
  {
    name: "Purple Light",
    PRIMARY: "#8944ab",
    SECONDARY: "#e1cfea",
    CENTER: "#ffffff",
    HIGHLIGHT: "#c4a2d5",
    DISABLED: "#d7c0e3",
    TEXT: "#a169bc",
    BACKGROUND: "#eadef0",
  },
  {
    name: "Purple Dark",
    PRIMARY: "#da8fff",
    SECONDARY: "#382441",
    CENTER: "#f0d1ff",
    HIGHLIGHT: "#6d477f",
    DISABLED: "#4a3056",
    TEXT: "#ae72cc",
    BACKGROUND: "#26192d",
  },

  {
    name: "Pink Light",
    PRIMARY: "#d30f45",
    SECONDARY: "#f4c2d0",
    CENTER: "#ffffff",
    HIGHLIGHT: "#e987a2",
    DISABLED: "#f0aec0",
    TEXT: "#dc3f6a",
    BACKGROUND: "#f7d5de",
  },
  {
    name: "Pink Dark",
    PRIMARY: "#ff6482",
    SECONDARY: "#411921",
    CENTER: "#ffbfcc",
    HIGHLIGHT: "#7f3241",
    DISABLED: "#56222c",
    TEXT: "#cc5068",
    BACKGROUND: "#2d1217",
  },
  {
    name: "Brown Light",
    PRIMARY: "#7f6545",
    SECONDARY: "#ded8d0",
    CENTER: "#ffffff",
    HIGHLIGHT: "#bfb2a2",
    DISABLED: "#d4cbc0",
    TEXT: "#99846a",
    BACKGROUND: "#e8e4de",
  },
  {
    name: "Brown Dark",
    PRIMARY: "#b59469",
    SECONDARY: "#2e261b",
    CENTER: "#e1d3c1",
    HIGHLIGHT: "#5a4a34",
    DISABLED: "#3d3223",
    TEXT: "#917654",
    BACKGROUND: "#201a13",
  },
];
