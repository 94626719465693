import styled from "styled-components";
import { RevealedAnswer, RevealedPangram } from "./Common";

interface AnswerListProps {
  revealedAnswers: string[];
  pangrams: string[];
}

const AnswerListContainer = styled.div`
  margin-top: 40px;
`;

const AnswerList = ({ revealedAnswers, pangrams }: AnswerListProps) => {
  return (
    <AnswerListContainer>
      {revealedAnswers.map((a) =>
        pangrams.includes(a) ? (
          <RevealedPangram key={a}>{a.toUpperCase()}</RevealedPangram>
        ) : (
          <RevealedAnswer key={a}>{a.toUpperCase()}</RevealedAnswer>
        )
      )}
    </AnswerListContainer>
  );
};

export default AnswerList;
