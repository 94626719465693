import styled from "styled-components";
import {
  THEMES,
  Theme,
  generateRandomTheme,
  generateDarkTheme,
  generateLightTheme,
} from "../models/Colors";
import { useEffect, useState } from "react";

interface ThemeSelectorProps {
  onThemeSelect: (t: Theme) => void;
}

const SelectorContainer = styled.div`
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
`;

const ThemeOption = styled.div<{ theme: Theme }>`
  min-width: 250px;
  flex: 1;
  height: 100px;
  line-height: 100px;
  font-size: 20px;
  color: ${({ theme }) => theme.PRIMARY};
  background-color: ${({ theme }) => theme.SECONDARY};
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.HIGHLIGHT};
  }
`;

const Highlight = styled.span<{ theme: Theme }>`
  background-color: ${({ theme }) => theme.BACKGROUND};
  padding: 8px 16px;
  border-radius: 5px;
`;

const ThemeSelector = ({ onThemeSelect }: ThemeSelectorProps) => {
  const [randomTheme, setRandomTheme] = useState<Theme>(
    () => THEMES[Math.floor(Math.random() * THEMES.length)]
  );
  const [trueRandomTheme, setTrueRandomTheme] =
    useState<Theme>(generateRandomTheme);
  const [randomLightTheme, setRandomLightTheme] =
    useState<Theme>(generateLightTheme);
  const [randomDarkTheme, setRandomDarkTheme] =
    useState<Theme>(generateDarkTheme);

  useEffect(() => {
    window.setTimeout(() => {
      let newTheme = THEMES[Math.floor(Math.random() * THEMES.length)];
      while (
        THEMES.length > 1 &&
        JSON.stringify(newTheme) === JSON.stringify(randomTheme)
      ) {
        newTheme = THEMES[Math.floor(Math.random() * THEMES.length)];
      }
      setRandomTheme(newTheme);
    }, 1000);
  }, [randomTheme]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    window.setInterval(() => {
      setTrueRandomTheme(generateRandomTheme());
      setRandomLightTheme(generateLightTheme());
      setRandomDarkTheme(generateDarkTheme());
    }, 500);
  }, []);

  return (
    <SelectorContainer>
      <ThemeOption
        theme={randomTheme}
        onClick={() => onThemeSelect(randomTheme)}
      >
        <Highlight theme={randomTheme}>Random</Highlight>
      </ThemeOption>
      <ThemeOption
        theme={trueRandomTheme}
        onClick={() => onThemeSelect(trueRandomTheme)}
      >
        <Highlight theme={trueRandomTheme}>True Random</Highlight>
      </ThemeOption>
      <ThemeOption
        theme={randomLightTheme}
        onClick={() => onThemeSelect(randomLightTheme)}
      >
        <Highlight theme={randomLightTheme}>Random Light</Highlight>
      </ThemeOption>
      <ThemeOption
        theme={randomDarkTheme}
        onClick={() => onThemeSelect(randomDarkTheme)}
      >
        <Highlight theme={randomDarkTheme}>Random Dark</Highlight>
      </ThemeOption>
      {THEMES.map((theme) => (
        <ThemeOption
          key={theme.name}
          theme={theme}
          onClick={() => onThemeSelect(theme)}
        >
          <Highlight theme={theme}>{theme.name}</Highlight>
        </ThemeOption>
      ))}
    </SelectorContainer>
  );
};

export default ThemeSelector;
