import { styled } from "styled-components";
import { Button, LeaderboardDivider } from "./Common";
import { useEffect, useRef, useState } from "react";

export const CHANGELOG_MESSAGES_NEW = [
  "[6/19/2024] Added the Event Log! You can now see who's been denying you those Pioneer badges. Other types of events are in the works, coming soon.",
];

export const CHANGELOG_MESSAGES_OLD = [
  "[5/12/2024] If the site is down feel free to just email dillonhammer@gmail.com with subject 'WWA Down' and I'll manually restart it when I'm able. Auto-restarting is high on my todo list so hopefully this won't be an issue soon.",
  "[5/12/2024] New theme dropped :)",
  "[4/27/2024] Streaks have been repaired",
  "[4/26/2024] Experiencing occasional issues with the pangram streak tracking, will work on a fix to repair streaks",
  "[3/20/2024] Announce SWA",
  "[12/10/2023] Revert the online indicator",
  "[12/6/2023] Have an outage",
  "[12/6/2023] Added online indicator to leaderboard",
  "[11/14/2023] Added 2 new badges!",
  "[11/14/2023] Made Changelog collapsible",
  "[11/14/2023] Your own Pioneer badge now shows which word(s) you have that nobody else has",
  "[11/14/2023] Moved the guess feedback to share space with the score slider, to save space on smaller screens",
  "[11/5/2023] Added badges! Click each one to see what it means",
];

const ChangelogContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 74px;
  padding: 0px 10px;
`;

const ChangelogContent = styled.div`
  display: inline-block;
  width: 100%;
  max-width: 500px;
`;

const ChangelogMessages = styled.div`
  text-align: left;
`;

const ChangelogButton = styled(Button)`
  margin-top: 20px;
`;

const ChangelogLine = styled.div`
  margin: 10px 0px;
`;

const Changelog = () => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (expanded && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [expanded]);

  return (
    <ChangelogContainer>
      <ChangelogContent>
        Changelog
        <LeaderboardDivider />
        <ChangelogMessages>
          {CHANGELOG_MESSAGES_NEW.map((m, index) => (
            <ChangelogLine key={index}>- {m}</ChangelogLine>
          ))}
          {expanded && (
            <>
              <LeaderboardDivider />
              {CHANGELOG_MESSAGES_OLD.map((m, index) => (
                <ChangelogLine key={index}>- {m}</ChangelogLine>
              ))}
              <div ref={scrollRef} />
            </>
          )}
        </ChangelogMessages>
        {expanded ? (
          <ChangelogButton onClick={() => setExpanded(false)}>
            Collapse
          </ChangelogButton>
        ) : (
          <ChangelogButton onClick={() => setExpanded(true)}>
            Expand
          </ChangelogButton>
        )}
      </ChangelogContent>
    </ChangelogContainer>
  );
};

export default Changelog;
